@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dot {
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.8);
  animation: pulse 1.5s infinite;
  stroke: #ffffff;
  stroke-opacity: 0.6;
}

.transition-opacity {
  transition: opacity 0.5s ease-in-out;
}

@keyframes pupilZoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@keyframes pulse_ex {
  0%,
  100% {
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.8);
  }
  50% {
    box-shadow: 0 0 30px rgba(255, 0, 0, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
